.popup-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  z-index: 1000;
}

.popup-card {
  border-radius: 34.756px;
  background: linear-gradient(
    104deg,
    #e9e9e9 0%,
    rgba(156, 156, 156, 0.21) 100%
  );
  width: 475px;
  height: 332.886px;
  flex-shrink: 0;
  padding: 30px;
}

.popup-header {
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
}

.cross-button {
  cursor: pointer;
}

.popup-form {
  gap: 30px;
}

.popup-button {
  width: 323.618px;
  height: 81.098px;
  flex-shrink: 0;
  vertical-align: middle;
  border-radius: 7.724px;
  background: #9c9a9a;
  color: #000;
  text-align: center;
  font-family: Airbnb;
  font-size: 23.171px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.854px; /* 90% */
  letter-spacing: 0.232px;
  cursor: pointer;
}

.vit-vellore-button {
  background: #4a4747;
  color: #fff;
}
