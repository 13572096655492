nav {
  height: 10vh;
  max-width: 100vw !important;
  width: 70vw;
  padding: 0px 80px;
}

.nav-content {
  padding: 0px 100px;
  font-family: Airbnb;
}

.nav-links {
  gap: 60px;
}
