.hero-container {
  width: 100vw;
  height: -webkit-fill-available;
  background-image: url("../../assets/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80vw;
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.hero-button-container {
  margin: 40px 0px 100px;
  gap: 10px;
  align-self: center;
  width: 500px;
  height: 54px;
  aspect-ratio: 500 / 54;
  flex-wrap: nowrap;
  filter: drop-shadow(0px 4px 95px rgba(0, 0, 0, 1));
}

.hero-button {
  width: -webkit-fill-available;
  padding: 18px 20px;
  border-radius: 45px 0px 0px 45px;
  background: #fdfdfd;
  color: #000;
  text-align: center;
  font-family: Airbnb;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register-now-button {
  font-weight: 1000;
  border-radius: 0px 45px 45px 0px;
}

.hero-button:hover {
  background: grey;
  color: white;
  transition: 0.3s ease-in-out;
}

.hero-monuments-image {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  z-index: -10;
}

@keyframes rotate-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.hero-flower-image {
  position: absolute;
  z-index: -10;
  width: 400px;
  transform-origin: center;
}

.left-flower {
  bottom: -200px;
  left: -200px;
  animation: rotate-right 10s linear infinite;
}

.right-flower {
  bottom: -200px;
  right: -200px;
  animation: rotate-left 10s linear infinite;
}

@media screen and (max-height: 870px) {
  .hero-image {
    height: 300px;
  }
  .hero-button-container {
    margin: 20px 0px 120px;
    width: 410px;
  }
  .hero-button {
    padding: 10px 7px;
  }
}

@media screen and (max-height: 540px) {
  .hero-image {
    height: 230px;
  }
}
